import styled from "styled-components"

export const Container = styled.div`
  background-color: #fff;
`

export const BlockSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  padding: 24px 36px;
  justify-content: center;
  @media screen and (min-width: 769px) {
    padding: 36px 120px;
  }
`
