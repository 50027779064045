import styled from "styled-components"

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  form {
    margin-bottom: 40px;
  }
`

export const FormTitle = styled.h3`
  align-self: center;
  margin: 40px 0 20px;
`

export const EmailInput = styled.div`
  /* Mobile First */
  display: flex;
  align-items: center;
  width: 100%;
  height: 3em;
  padding: 10px 0 10px 16px;
  border-radius: 4px;
  border: 2px solid #ededed;
  background: #ededed;
  & + div {
    margin-top: 12px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #333;
    font-size: 18px;
    &::placeholder {
      color: #777;
    }
  }
  /* RESPONSIVE */
  @media screen and (min-width: 769px) {
    width: 100%;

    input {
      font-size: 0.92em;
    }
  }
`
export const InputAndButtonWrap = styled.div`
  /* Mobile First */
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-columns: 70% 26%;
  grid-gap: 4%;

  /* RESPONSIVE */
  @media screen and (min-width: 376px) {
  }
`

export const PasswordInput = styled.div`
  /* Mobile First */
  display: flex;
  align-items: center;
  padding: 10px 0 10px 16px;
  border-radius: 4px;
  border: 2px solid #ededed;
  width: 100%;
  height: 3em;
  background: #ededed;
  & + div {
    margin-top: 12px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #333;
    font-size: 12px;
    &::placeholder {
      color: #777;
    }
  }
`
export const ButtonWrap = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 8px;
  justify-content: center;
`

export const Button = styled.button`
  /* Mobile First */
  justify-self: end;
  padding: 0 16px;
  width: 100%;
  min-width: 66px;
  border-radius: 4px;
  border: 0;
  background: #b33f40;

  color: #fafafa;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  transition: background-color 0.2s;
`
export const Button2 = styled.a`
  width: 200px;
  height: 48px;
  padding: 18px;
  background-color: #00649f;
  border: none;
  border-radius: 32px;

  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #fff;
  :hover {
    cursor: pointer;
  }
`
