import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const Container = styled.div`
  display: flex;
  position: relative;
  padding: 0 30px;
  min-height: 70vh;
  background: #0c0c0c;
  justify-content: center;
  align-items: center;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 25, 0.65) 10%,
      rgba(0, 0, 0, 0.9) 100%
    );
    z-index: 1;
  }
`

export const ContainerBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const ImageItem = styled(GatsbyImage)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const Row = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  z-index: 1;
  grid-template-areas: ${props => {
    return props.imgStart ? `'col2 col1'` : `'col1 col2'`
  }};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${props =>
      props.imgStart ? `'col2 col2' 'col1 col1'` : `'col1 col1' 'col2 col2'`};
  }
`

export const ColumnA = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`

export const ColumnB = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`

export const Heading = styled.h2`
  margin-bottom: 24px;
  line-height: 1.1;
  color: #fff;
`

export const Description = styled.p`
  margin-bottom: 35px;
  line-height: 24px;
  color: #fff;
  text-align: justify;
`

export const Button = styled.button`
  padding: 0 16px;
  width: 240px;
  height: 48px;
  border-radius: 4px;
  border: 0;
  background-color: #00649f;
  color: #fafafa;
  text-transform: uppercase;
  font-size: 12px;

  transition: background-color 0.2s;
  :hover {
    opacity: 0.9;
  }
`
