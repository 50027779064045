import React from "react"
import reqFile from "files/req.pdf"
import {
  EmailInput,
  Button,
  InputAndButtonWrap,
  PasswordInput,
  FormTitle,
  FormWrap,
  ButtonWrap,
  Button2,
} from "./ExamsForm.styles"
const ExamsForm = ({ btn1Text, formTitle, btn2Text }) => {
  return (
    <>
      <FormTitle>{formTitle}</FormTitle>
      <FormWrap>
        <form
          name="form_central"
          id="form_central"
          method="post"
          action="https://central.image2doc.com.br/login"
        >
          <EmailInput>
            <input
              type="text"
              autoComplete="off"
              id="input1"
              name="email"
              placeholder="Email"
            />
          </EmailInput>
          <InputAndButtonWrap>
            <PasswordInput>
              <input
                type="password"
                id="input2"
                name="password"
                placeholder="Senha"
              />
            </PasswordInput>
            <Button type="submit">Acessar</Button>
          </InputAndButtonWrap>
        </form>

        <ButtonWrap>
          <Button2
            href="https://exames.image2doc.com.br/#/login/protocolo"
            target="_blank"
          >
            {btn1Text}
          </Button2>
          <Button2 href={reqFile} target="_blank">
            {btn2Text}
          </Button2>
        </ButtonWrap>
      </FormWrap>
    </>
  )
}

export default ExamsForm
