import * as React from "react"
import HeroSection from "components/HeroSection"
import CommonSmallSection from "components/CommonSmallSection"
import ServicesSection from "components/ServicesSection"
import OurMission from "components/CommonSection/OurMission"
import CommonSectionBackgroundImage from "components/CommonSectionBackgroundImage"
import Seo from "components/seo"
//hooks
import { useIndexPageDataQuery } from "../hooks/useIndexPageDataQuery"

const IndexPage = () => {
  const {
    heroContent,
    formSectionContent,
    serviceSectionContent,
    ourMissionSectionContent,
    whereWeAreSectionContent,
  } = useIndexPageDataQuery()

  return (
    <>
      <Seo title="Home" />
      <HeroSection {...heroContent} />
      <CommonSmallSection {...formSectionContent} />
      <ServicesSection {...serviceSectionContent} />
      <OurMission {...ourMissionSectionContent} />
      <CommonSectionBackgroundImage {...whereWeAreSectionContent} />
    </>
  )
}

export default IndexPage
