import React from "react"
import ExamsForm from "components/Forms/ExamsForm"
import { BlockSection, Container } from "./CommonSmallSection.styles"

const CommonSmallSection = ({
  title,
  subtitle,
  formTitle,
  btn1Text,
  btn2Text,
}) => {
  return (
    <Container>
      <BlockSection>
        <h2>{title}</h2>
        <p>{subtitle}.</p>
        <ExamsForm
          formTitle={formTitle}
          btn1Text={btn1Text}
          btn2Text={btn2Text}
        />
      </BlockSection>
    </Container>
  )
}

export default CommonSmallSection
