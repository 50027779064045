import React from "react"
import { getImage } from "gatsby-plugin-image"

import {
  Container,
  ContainerBackground,
  ImageItem,
  Heading,
  Description,
  Row,
  ColumnA,
  ColumnB,
  Button,
} from "./CommonSectionBackgroundImage.styles"

const CommonSectionBackgroundImage = ({
  title,
  description,
  btnText,
  backgroundImage: { alt, pluginImage },
}) => {
  const convertedImage = getImage(pluginImage)
  return (
    <Container>
      <ContainerBackground>
        <ImageItem image={convertedImage} alt={alt} />
      </ContainerBackground>

      <Row>
        <ColumnA></ColumnA>
        <ColumnB>
          <Heading>{title}</Heading>
          <Description>{description}</Description>
          <Button>{btnText}</Button>
        </ColumnB>
      </Row>
    </Container>
  )
}

export default CommonSectionBackgroundImage
